@import '../../../styles/customMediaQueries.css';

.toggleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  margin: 0 0;

  @media (--viewportMedium) {
    justify-content: center;
  }
}

.toggle {
  cursor: pointer;
	text-indent: -9999px;
	width: 38px;
	height: 20px;
	background: var(--colorGrey500);
	display: block;
	border-radius: 100px;
	position: relative;
	border: 1px solid var(--colorGrey500);
	margin: 0;
  flex-shrink: 0;

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 14px;
    height: 14px;
    background: #fff;
    border-radius: 90px;
    /* transition: 0.3s; */
    padding: 0;
  }
}

.toggleActive {
  background: var(--marketplaceColor);
  border: 1px solid var(--marketplaceColor);

  &:after {
    left: calc(100% - 5px);
    transform: translateX(-73%);
  }
}

.toggleItem {
  display: inline-block;
  width: auto;
  min-width: 50px;
  padding: 0;
  margin: 0;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  /* Font */
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  line-height: 24px;
  
  text-align: center;
  text-decoration: none;
  border: none;
  box-shadow: none;

  color: var(--colorGrey500);

  &:focus,
  &:hover {
    outline: none;
    border: none;
    box-shadow: none;  
    text-decoration: none;
  }

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
  }

  &:first-of-type {
    display: none;
  }

  @media (--viewportMedium) {
    margin: 0 10px;
    min-width: 90px;

    &:first-of-type {
      display: inline-block;
    }
  }
}

.toggleItemActive {
  color: var(--colorGrey700);
}

.desktopText {
  display: none;
  
  @media (--viewportMedium) {
    display: inline-block;
  }
}

.mobileText {
  display: inline-block;

  @media (--viewportMedium) {
    display: none;
  }
}
